import { useDisclosure, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import {
  authedDelete,
  authedGet,
  authedPatch,
  authedPost,
  parseListResponse,
} from "src/api";
import ConfirmationModal from "src/components/design-system/ConfirmationModal";
import { IgnoreRule } from "src/hooks/useIgnoreFindingRule";
import { getOrgId } from "src/utils/auth";

export interface TargetSite {
  id: number;
  orgId: number;
  notFoundSelector?: string;
  url: string;
  name: string;
  helpdocProvider?: string;
  usePathname?: boolean;
  loginConfig?: number;
}

export interface LoginConfig {
  id: number;
  orgId: number;
  name: string;
  url: string;
  usernameSelector?: string;
  username?: string;
  passwordSelector?: string;
  password?: string;
}

export interface RunConfig {
  uid: string;
  orgId: string;
  schedule: string;
  targets: number[];
  persona?: number;
}

export interface RunStatus {
  isRunning: boolean;
  startTime: string;
  pagesVisited: number;
  pagesToVisit: number;
}

export interface ConfigContextIF {
  targetSites: TargetSite[];
  loginConfigs: LoginConfig[];
  loginConfigsById: Record<number, LoginConfig>;
  runConfigs: RunConfig[];
  setTargetSites: (targetSites: TargetSite[]) => void;
  setLoginConfigs: (loginConfigs: LoginConfig[]) => void;
  setRunConfigs: (runConfigs: RunConfig[]) => void;
  createTargetSite: (newTargetSite: Partial<TargetSite>) => void;
  updateTargetSite: (newTargetSite: TargetSite) => void;
  deleteTargetSite: (id: number) => void;
  createLoginConfig: (newLoginConfig: Partial<LoginConfig>) => Promise<number>;
  updateLoginConfig: (newLoginConfig: LoginConfig) => void;
  createDigConfig: () => void;
  updateDigConfig: (newRunConfig: RunConfig) => void;
  triggerDig: () => void;
  runStatus?: RunStatus;
  ignoreRules: IgnoreRule[];
  deleteIgnoreRule: (ruleIdToIgnore: number) => void;
  createIgnoreRule: (ruleToCreate: IgnoreRule) => Promise<number>;
}

export const ConfigContext = React.createContext<ConfigContextIF>({
  targetSites: [],
  setTargetSites: () => {
    console.error("setTargetSites called before ready");
  },
  loginConfigs: [],
  loginConfigsById: {},
  setLoginConfigs: () => {
    console.error("setLoginConfigs called before ready");
  },
  runConfigs: [],
  setRunConfigs: () => {
    console.error("setRunConfig called before ready");
  },
  createTargetSite: () => {
    console.error("createTargetSite called before ready");
  },
  updateTargetSite: () => {
    console.error("updateTargetSite called before ready");
  },
  deleteTargetSite: () => {
    console.error("deleteTargetSite called before ready");
  },
  createLoginConfig: () => {
    console.error("createLoginConfig called before ready");
    return new Promise((_, reject) => {
      reject("not ready");
    });
  },
  updateLoginConfig: () => {
    console.error("updateLoginConfig called before ready");
  },
  createDigConfig: () => {
    console.error("createDigConfig called before ready");
  },
  updateDigConfig: () => {
    console.error("updateDigConfig called before ready");
  },
  triggerDig: () => {
    console.error("triggerDig called before ready");
  },
  ignoreRules: [],
  deleteIgnoreRule: () => {
    console.error("deleteIgnoreRule called before ready");
  },
  createIgnoreRule: () => {
    console.error("createIgnoreRule called before ready");
    return new Promise((_, reject) => {
      reject("not ready");
    });
  },
});

const ConfigContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [targetSites, setTargetSites] = useState<TargetSite[]>([]);
  const [loginConfigs, setLoginConfigs] = useState<LoginConfig[]>([]);
  const loginConfigsById = loginConfigs.reduce(
    (all: Record<number, LoginConfig>, cur) => {
      all[cur.id] = cur;
      return all;
    },
    {}
  );
  const [runConfigs, setRunConfigs] = useState<RunConfig[]>([]);
  const [ignoreRules, setIgnoreRules] = useState<IgnoreRule[]>([]);
  const toast = useToast();
  const {
    isOpen: isDigConfirmModalOpen,
    onOpen: onDigConfirmModalOpen,
    onClose: onDigConfirmModalClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchTargetSites = async () => {
      const fetchedTargetSites = await authedGet("/targetSites").then(
        parseListResponse
      );
      setTargetSites(fetchedTargetSites);
    };
    const fetchLoginConfigs = async () => {
      const fetchedLoginConfigs = await authedGet("/loginConfigs").then(
        parseListResponse
      );
      setLoginConfigs(fetchedLoginConfigs);
    };
    const fetchRunConfig = async () => {
      const fetchedRunConfigs = await authedGet("/digs/config").then(
        parseListResponse
      );
      setRunConfigs(fetchedRunConfigs);
    };
    const fetchIgnoreRules = async () => {
      const fetchedIgnoreRules = await authedGet("/findings/ignore/rule").then(
        parseListResponse
      );
      setIgnoreRules(fetchedIgnoreRules);
    };
    fetchTargetSites();
    fetchLoginConfigs();
    fetchRunConfig();
    fetchIgnoreRules();
  }, []);

  const createTargetSite = useCallback(
    async (targetSite: Partial<TargetSite>) => {
      const newSites = await authedPost(
        `/targetSites`,
        targetSite
      ).then((resp) => resp.json());
      setTargetSites(newSites);
    },
    []
  );

  const updateTargetSite = useCallback(
    async (targetSite: TargetSite) => {
      const newSites = await authedPatch(
        `/targetSites/${targetSite.id}`,
        targetSite
      ).then((resp) => resp.json());
      setTargetSites(newSites);
    },
    [setTargetSites]
  );

  const deleteTargetSite = useCallback(
    async (id: number) => {
      const newSites = await authedDelete(`/targetSites/${id}`).then((resp) =>
        resp.json()
      );
      setTargetSites(newSites);
    },
    [setTargetSites]
  );

  const createLoginConfig = useCallback(
    async (persona: Partial<LoginConfig>) => {
      const newLoginConfigs = await authedPost(
        `/loginConfigs`,
        persona
      ).then((resp) => resp.json());
      setLoginConfigs(newLoginConfigs);
      return newLoginConfigs.find(
        (config: LoginConfig) =>
          config.url === persona.url && config.username === persona.username
      ).id;
    },
    []
  );

  const updateLoginConfig = useCallback(async (persona: LoginConfig) => {
    const newLoginConfigs = await authedPatch(
      `/loginConfigs/${persona.id}`,
      persona
    ).then((resp) => resp.json());
    setLoginConfigs(newLoginConfigs);
  }, []);

  const triggerDig = useCallback(async () => {
    try {
      const triggeredDig = await authedPost("/dig");
      if (triggeredDig.status > 204) {
        throw new Error(
          `failed to trigger a new dig; request failed with ${triggeredDig.statusText}`
        );
      }
      toast({
        isClosable: true,
        title: "Done!",
        status: "success",
        description: "Triggered new dig",
        variant: "darkSuccess",
      });
    } catch (e: any) {
      console.error(e);
      toast({
        title: "That didn’t work.",
        description: e.message,
        status: "error",
        isClosable: true,
      });
    }
  }, [toast]);

  const getRunStatus = useCallback(async () => {
    return authedGet("/run/status").then((res) => res.json());
  }, []);

  const { data: runStatus } = useSWR<RunStatus>("run-status", getRunStatus, {
    refreshInterval: 60000,
  });

  const createDigConfig = useCallback(async () => {
    try {
      const createdRunConfigRes = await authedPost(`/digs/config`, {
        orgId: `${getOrgId()}`,
        schedule: "0 0 * * 0",
      }).then((response) => response.json());
      setRunConfigs(createdRunConfigRes);
      toast({
        isClosable: true,
        title: "We did it!",
        status: "success",
        description: "Your weekly dig has been scheduled",
        variant: "darkSuccess",
      });
    } catch (e: any) {
      toast({
        title: "That didn’t work.",
        description: e.message,
        status: "error",
        isClosable: true,
      });
      console.error(e);
    }
  }, [toast, setRunConfigs]);

  const updateDigConfig = useCallback(async (runConfigToUpdate: RunConfig) => {
    try {
      const updatedConfigRes = await authedPatch(
        `/digs/config/${runConfigToUpdate.uid}`,
        runConfigToUpdate
      );
      if (updatedConfigRes.status !== 200) {
        throw new Error("failed to update");
      }
      // todo update state
    } catch (e) {
      console.error(e);
    }
  }, []);

  const deleteIgnoreRule = useCallback(
    async (ruleIdToDelete: number) => {
      const newRules = await authedDelete(
        `/findings/ignore/rule/${ruleIdToDelete}`
      ).then(parseListResponse);
      setIgnoreRules(newRules);
      toast({
        status: "success",
        title: "Unignored",
        description: "These issues will not be ignored on subsequent digs",
        variant: "darkSuccess",
      });
    },
    [setIgnoreRules, toast]
  );

  const createIgnoreRule = useCallback(
    async (newRule: IgnoreRule) => {
      const res = await authedPost(
        `/findings/ignore/rule`,
        newRule
      ).then((resp) => resp.json());
      setIgnoreRules([newRule, ...ignoreRules]);
      return res.ignoredFindings;
    },
    [ignoreRules]
  );

  return (
    <ConfigContext.Provider
      value={{
        targetSites,
        setTargetSites,
        loginConfigs,
        loginConfigsById,
        setLoginConfigs,
        runConfigs,
        setRunConfigs,
        createTargetSite,
        updateTargetSite,
        deleteTargetSite,
        createLoginConfig,
        updateLoginConfig,
        createDigConfig,
        updateDigConfig,
        triggerDig: onDigConfirmModalOpen,
        runStatus,
        ignoreRules,
        deleteIgnoreRule,
        createIgnoreRule,
      }}
    >
      <ConfirmationModal
        scareText="This will trigger a new dig."
        actionVerb="Dig"
        isOpen={isDigConfirmModalOpen}
        onClose={onDigConfirmModalClose}
        onConfirm={() => triggerDig()}
        actionButtonVariant="primary"
      />
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
