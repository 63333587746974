import React, { useCallback, useEffect, useState } from "react";
import { authedGet, parseListResponse } from "src/api";
import Stripe from "stripe";

export interface StripeProducts {
  products: Stripe.Product[];
}

export const BillingContext = React.createContext<StripeProducts>({
  products: [],
});

const BillingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [products, setProducts] = useState<Stripe.Product[]>([]);

  const getBilling = useCallback(async () => {
    const productsResp = await authedGet("/billing/products").then(
      parseListResponse
    );
    setProducts(productsResp);
  }, []);

  useEffect(() => {
    getBilling();
  }, [getBilling]);

  return (
    <BillingContext.Provider value={{ products }}>
      {children}
    </BillingContext.Provider>
  );
};

export default BillingContextProvider;
