import { useToast } from "@chakra-ui/react";
import { clone, without } from "ramda";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  authedDelete,
  authedGet,
  authedPost,
  parseListResponse,
} from "src/api";
import { SitePageContext } from "./sitePagesContextProvider";

export interface Linkage {
  id: number;
  orgId: number;
  createdAt: string;
  appPage: number;
  helpdocPage: number;
}

export interface LinkageContextIF {
  linkages: Record<number, number[]>;
  createLinkage: (appPage: number, helpdocPage: number) => void;
  deleteLinkage: (appPage: number, helpdocPage: number) => void;
}

export const LinkageContext = React.createContext<LinkageContextIF>({
  linkages: {},
  createLinkage: () => console.error("createLinkage called before ready"),
  deleteLinkage: () => console.error("deleteLinkage called before ready"),
});

const LinkageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const toast = useToast();
  const [linkages, setLinkages] = useState<Record<number, number[]>>({});
  const { sitePagesById } = useContext(SitePageContext); // context within context seems SCARY

  const getLinkages = useCallback(async () => {
    const linkagesResp = await authedGet("/linkages").then(parseListResponse);
    setLinkages(linkagesResp);
  }, []);

  useEffect(() => {
    getLinkages();
  }, [getLinkages]);

  const createLinkage = useCallback(
    async (appPage: number, helpdocPage: number) => {
      await authedPost("/linkages", {
        appPage,
        helpdocPage,
      }).then((res) => res.json());
      const newLinkages = clone(linkages);
      if (newLinkages[appPage]) {
        newLinkages[appPage].push(helpdocPage);
      } else {
        newLinkages[appPage] = [helpdocPage];
      }
      if (newLinkages[helpdocPage]) {
        newLinkages[helpdocPage].push(appPage);
      } else {
        newLinkages[helpdocPage] = [appPage];
      }
      setLinkages(newLinkages);
      toast({
        isClosable: true,
        title: "Linkage created",
        status: "success",
        variant: "darkSuccess",
        description: `${
          sitePagesById[appPage]?.name || "This app page"
        } is now linked to ${
          sitePagesById[helpdocPage]?.name || "this helpdoc"
        }`,
      });
    },
    [linkages, toast, sitePagesById]
  );

  const deleteLinkage = useCallback(
    async (appPage: number, helpdocPage: number) => {
      const res = await authedDelete(`/linkages`, {
        appPage,
        helpdocPage,
      });
      if (res.ok) {
        const newLinkages = clone(linkages);
        newLinkages[appPage] = without([helpdocPage], newLinkages[appPage]);
        newLinkages[helpdocPage] = without([appPage], newLinkages[helpdocPage]);
        setLinkages(newLinkages);
        toast({
          isClosable: true,
          title: "Linkage deleted",
          status: "success",
          variant: "darkSuccess",
          description: `${
            sitePagesById[appPage]?.name || "This app page"
          } is no longer linked to ${
            sitePagesById[helpdocPage]?.name || "this helpdoc"
          }`,
        });
      } else {
        toast({
          isClosable: true,
          title: "Whoops",
          status: "error",
          description: `${
            sitePagesById[appPage]?.name || "This app page"
          } could not be unlinked from ${
            sitePagesById[helpdocPage]?.name || "this helpdoc"
          } because ${res.statusText}`,
        });
      }
    },
    [linkages, toast, sitePagesById]
  );

  return (
    <LinkageContext.Provider
      value={{
        linkages,
        createLinkage,
        deleteLinkage,
      }}
    >
      {children}
    </LinkageContext.Provider>
  );
};

export default LinkageContextProvider;
