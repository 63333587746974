const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Icon-/-Dig"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M8.43372897,9.22104955 C8.43837934,9.22491717 8.4428879,9.22895218 8.44724578,9.23314661 L10.5063077,11.2149853 C10.6057863,11.3107331 10.6088108,11.4689955 10.513063,11.5684741 L10.5110339,11.5705579 L8.74328613,13.3649292 C8.22485352,13.9747925 8.28038534,14.5638021 8.90988159,15.131958 C9.50003433,15.6646042 10.0825428,15.705221 10.6574069,15.2538086 L10.7722778,15.1569657 L12.4800048,13.455276 C12.5778086,13.3578179 12.7360996,13.3580982 12.8335576,13.455902 L12.8353138,13.4576822 L14.8143815,15.4839086 C14.9108563,15.5826823 14.9089927,15.7409626 14.8102189,15.8374375 C14.8064867,15.8410828 14.8026413,15.8446105 14.7986883,15.8480152 C12.4479681,17.8727167 10.592607,19.1921568 9.23260498,19.8063354 C7.13471393,20.7537457 3.16351318,21.5426025 2.81976318,21.1745605 C2.47601318,20.8065186 3.3139294,16.8480751 4.23126221,14.8008423 C4.82567714,13.4742728 6.10910896,11.6251668 8.08155767,9.25352431 C8.16977923,9.14720026 8.32750623,9.13270618 8.43372897,9.22104955 Z M21.7272486,2.07344064 C22.1852958,2.47497 22.3423291,3.09741851 22.1983486,3.94078619 L22.1597789,4.13933114 L16.9006836,9.62 L15.9087326,8.653 L10.1223889,14.6109493 C9.92998615,14.8090295 9.61343712,14.813632 9.41535687,14.6212293 C9.23928554,14.4502046 9.21608552,14.181089 9.34823429,13.9842789 L9.4050769,13.9141972 L15.1927326,7.955 L14.25,7.03506784 L19.5059974,1.7040169 C20.4935501,1.51788317 21.2339671,1.64102442 21.7272486,2.07344064 Z"
        id="Combined-Shape"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default Icon;
