import { WarningIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  scareText: string | React.ReactNode;
  actionVerb: string;
  actionButtonVariant?: string;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  scareText,
  actionVerb,
  actionButtonVariant = "warning",
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <WarningIcon mr="2" /> Are you sure?
        </ModalHeader>
        <ModalBody>{scareText}</ModalBody>
        <ModalFooter>
          <Button
            variant={actionButtonVariant}
            mr="2"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {actionVerb}
          </Button>
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
