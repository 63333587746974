import development from "./development.json";
import production from "./production.json";

interface ConfigInterface {
  API_HOST: string;
  APP_HOST: string;
  SLACK_CLIENT_ID: string;
  SLACK_APP_ID: string;
  SEGMENT_WRITE_KEY: string;
  LIGHTSTEP_INGEST_KEY: string;
}

export default (process.env.NODE_ENV === "development" &&
process.env.REACT_APP_TIM !== "TIM"
  ? development
  : production) as ConfigInterface;
