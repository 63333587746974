import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Input,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { post } from "src/api";
import Link from "src/components/design-system/Link";
import PageHeader from "src/components/design-system/PageHeader";
import PasswordInput from "src/components/design-system/PasswordInput";
import { setToken } from "src/utils/auth";

const LoginFormGridItem = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [staySignedIn, setStaySignedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const login = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      const signinResult = await post("/public/signin", {
        username,
        password,
        staySignedIn,
      }).then((response) => response.json());
      if (signinResult.jwt) {
        setToken(signinResult.jwt);
        window.location.reload();
      } else {
        throw new Error("malformed response");
      }
    } catch (e) {
      console.warn(e);
      setError("check your password");
      setLoading(false);
    }
  }, [username, password, staySignedIn]);

  const submitOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        login();
      }
    },
    [login]
  );

  return (
    <GridItem rowSpan={9}>
      <Grid templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={1} />
        <GridItem colSpan={2}>
          <PageHeader fontSize="44px" textAlign="center">
            Welcome back.
          </PageHeader>
          <VStack
            marginTop="8"
            spacing="32px"
            width="288px"
            fontSize="12px"
            onKeyPress={submitOnEnter}
          >
            <Input
              type="email"
              id="username"
              placeholder="Your email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
              spellCheck={false}
            />
            <Box w="100%">
              <PasswordInput password={password} setPassword={setPassword} />
              <Flex alignItems="center" marginTop="2">
                <Checkbox
                  defaultIsChecked
                  checked={staySignedIn}
                  onChange={() => setStaySignedIn(!staySignedIn)}
                  size="md"
                  colorScheme="dark"
                >
                  <Text fontSize="12px">Keep me logged in</Text>
                </Checkbox>
                <Spacer />
                <Link to="/forgot" color="dark.600">
                  Forgot password
                </Link>
              </Flex>
            </Box>
            <Button
              w="100%"
              variant="secondary"
              onClick={login}
              isLoading={loading}
              loadingText="Submitting"
              type="submit"
            >
              Log in
            </Button>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
          </VStack>
          <Text mt="26px" fontWeight="500">
            Don’t have an account?{" "}
            <Link to="/signup" color="blue.700">
              Sign up
            </Link>
          </Text>
        </GridItem>
        <GridItem colSpan={1} />
      </Grid>
    </GridItem>
  );
};

export default LoginFormGridItem;
