const Input = {
  variants: {
    outline: {
      field: {
        borderColor: "gray.medium",
        _placeholder: { color: "dark.medium" },
      },
    },
    unstyled: {
      field: { _placeholder: { color: "dark.medium" } },
    },
    flushed: {
      field: { _placeholder: { color: "dark.medium" } },
    },
  },
};

export default Input;
