const Icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="-2 -2 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 10.0607L13.5 3.31066L15.1287 4.93934L8.37868 11.6893H6.75V10.0607ZM9.21967 12.9697L16.7197 5.46967C17.0126 5.17678 17.0126 4.7019 16.7197 4.40901L14.0303 1.71967C13.7374 1.42678 13.2626 1.42678 12.9697 1.71967L5.46967 9.21967C5.32902 9.36032 5.25 9.55109 5.25 9.75V12.4393C5.25 12.8536 5.58579 13.1893 6 13.1893H8.68934C8.88825 13.1893 9.07902 13.1103 9.21967 12.9697ZM14.25 10.1893C13.8358 10.1893 13.5 10.5251 13.5 10.9393V15.0643C13.5 15.2714 13.3321 15.4393 13.125 15.4393H3.375C3.16789 15.4393 3 15.2714 3 15.0643V5.31434C3 5.10723 3.16789 4.93934 3.375 4.93934H7.5C7.91421 4.93934 8.25 4.60355 8.25 4.18934C8.25 3.77513 7.91421 3.43934 7.5 3.43934H3.375C2.33947 3.43934 1.5 4.27881 1.5 5.31434V15.0643C1.5 16.0999 2.33947 16.9393 3.375 16.9393H13.125C14.1605 16.9393 15 16.0999 15 15.0643V10.9393C15 10.5251 14.6642 10.1893 14.25 10.1893Z"
      fill="currentColor"
    />
  </svg>
);

export default Icon;
