import { Link as StyledLink, LinkProps } from "@chakra-ui/react";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { scarab } from "src/theme/foundations/colors";

const Link = ({ children, ...rest }: RouterLinkProps & LinkProps) => {
  return (
    <StyledLink
      as={RouterLink}
      color={rest.color || scarab}
      _hover={{ color: "blue.dark" }}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
