const Select = {
  variants: {
    outline: {
      field: {
        borderColor: "gray.dark",
        _placeholder: { color: "dark.light" },
      },
    },
  },
};

export default Select;
