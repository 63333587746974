import { fog } from "../foundations/colors";

const Button = {
  baseStyle: {
    color: "dark.dark",
    _hover: {
      bg: fog,
      _disabled: {
        bg: "dark.md",
      },
    },
  },
  variants: {
    primary: {
      bgColor: "yellow.medium",
      color: "dark.dark",
      boxShadow: "md",
      _hover: {
        color: "dark.dark",
        boxShadow: "lg",
      },
    },
    secondary: {
      bg: "dark.dark",
      color: "white",
      _hover: {
        bg: "dark.dark",
        boxShadow: "md",
      },
    },
    tertiary: {
      border: "1px solid",
      borderColor: "dark.medium",
      color: "dark.dark",
      _hover: {
        borderColor: "dark.dark",
        boxShadow: "md",
        textDecoration: "none",
      },
    },
    warning: {
      bg: "red.medium",
      color: "white",
      _hover: {
        bg: "red.dark",
        boxShadow: "md",
      },
    },
    tableButton: {
      color: "dark.dark",
    },
  },
};

export default Button;
