const Card = {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 6,
  },
  variants: {
    rounded: {
      padding: 8,
      borderRadius: "xl",
      boxShadow: "0px 1px 6px 0px rgba(47, 54, 48, 0.15)", // i fucking suck this is darkForest or some shit im so sorry
    },
    ghost: {
      padding: 8,
      borderRadius: "xl",
      borderStyle: "dashed",
      borderColor: "gray.medium",
      borderWidth: "1px",
    },
  },
  defaultProps: {
    variant: "rounded",
  },
};

export default Card;
