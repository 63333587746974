import { Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import spacetime from "spacetime";
import useSWR from "swr";
import { authedGet } from "src/api";
import { RunStatus } from "src/contexts/configContextProvider";

const RunStatusPopoverBody = () => {
  // intentionally not using context so we can get the snappier refresh interval
  const getRunStatus = useCallback(async () => {
    return authedGet("/run/status").then((res) => res.json());
  }, []);

  const { data: runStatus } = useSWR<RunStatus>("run-status", getRunStatus, {
    refreshInterval: 10000,
  });

  if (!runStatus?.isRunning) {
    return <></>;
  }

  return (
    <Text maxW={250}>
      <Text>
        A dig started{" "}
        {spacetime().since(spacetime(runStatus.startTime)).rounded}.
      </Text>
      {runStatus.pagesVisited
        ? `${runStatus.pagesVisited} pages have been visited, and at least `
        : ""}
      {runStatus.pagesVisited
        ? `${runStatus.pagesToVisit} pages are left to visit.`
        : ""}
    </Text>
  );
};

export default RunStatusPopoverBody;
