const Popover = {
  parts: ["content"],
  baseStyle: () => ({
    content: {
      ":focus": { boxShadow: "var(--chakra-shadows-xl) !important" },
    },
  }),
};

export default Popover;
