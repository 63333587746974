import React, { useCallback, useEffect, useState } from "react";
import { authedGet, authedPost } from "src/api";
import { getOrgId } from "src/utils/auth";

export interface UserPreferences {
  orgId: number;
  preferences: {
    emailPreferences: EmailPreferences;
  };
}

export interface EmailPreferences {
  digCompletion: boolean;
}

const defaultPreference = {
  orgId: getOrgId(),
  preferences: {
    emailPreferences: {
      digCompletion: false,
    },
  },
};

export interface UserPreferenceIF {
  preferences: UserPreferences;
  setUserPreferences: (newPreferences: UserPreferences) => Promise<void>;
}

export const PreferenceContext = React.createContext<UserPreferenceIF>({
  preferences: defaultPreference,
  setUserPreferences: () => {
    return new Promise((_, reject) => {
      reject("setUserPreferences not ready");
    });
  },
});

const PreferenceContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [preferences, setPreferences] = useState<UserPreferences>(
    defaultPreference
  );

  const fetchPreferences = useCallback(async () => {
    const preferenceResponse = await authedGet("/userPreferences");
    if (preferenceResponse.ok) {
      setPreferences(await preferenceResponse.json());
    }
  }, []);

  const updatePreferences = useCallback(
    async (newPreferences: UserPreferences) => {
      const preferenceResponse = await authedPost(
        "/userPreferences",
        newPreferences
      ).then((resp) => resp.json());
      setPreferences(preferenceResponse);
    },
    []
  );

  useEffect(() => {
    fetchPreferences();
  }, [fetchPreferences]);

  return (
    <PreferenceContext.Provider
      value={{
        preferences,
        setUserPreferences: updatePreferences,
      }}
    >
      {children}
    </PreferenceContext.Provider>
  );
};

export default PreferenceContextProvider;
