const Flag = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.31857V1.37143V4.16714V11.6571C0 11.8465 0.161177 12 0.359999 12C0.558821 12 0.719998 11.8465 0.719998 11.6571V5.4179C0.861877 5.46245 1.04842 5.48571 1.38449 5.48571L6.83998 5.48571C6.91787 5.48571 6.99367 5.46165 7.05598 5.41714C7.21504 5.30353 7.24727 5.08863 7.12798 4.93714L5.39998 2.74286L7.12798 0.548571C7.17471 0.489224 7.19998 0.417041 7.19998 0.342857C7.19998 0.153502 7.0388 0 6.83998 0H1.38449C0.903075 0 0.728501 0.0477387 0.552501 0.137382C0.376502 0.227026 0.238376 0.358575 0.144251 0.526194C0.0501254 0.693813 0 0.860074 0 1.31857Z"
      fill="currentColor"
    />
  </svg>
);

export default Flag;
