import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import UnauthedLayout from "./layout";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import SignupForm from "./SignUpForm";

interface Props {
  component: "login" | "signup" | "forgot" | "reset";
}

const typeToComponent = {
  login: LoginForm,
  signup: SignupForm,
  forgot: ForgotPasswordForm,
  reset: ResetPasswordForm,
};

const UnauthedPage = ({ component }: Props) => {
  const ContentItem = typeToComponent[component];
  return <UnauthedLayout contentGridItem={ContentItem} />;
};

export default UnauthedPage;
