import { Center, VStack, Spacer, Text, BoxProps } from "@chakra-ui/react";
import React from "react";
import Flower from "./flower.jsx";

const Loader = ({ height = "100vh", ...props }: BoxProps) => {
  return (
    <Center height={height} {...props}>
      <VStack>
        <Flower />
        <Spacer />
        <Text>Loading…</Text>
      </VStack>
    </Center>
  );
};

export default Loader;
