const Sticky = {
  baseStyle: {
    bgColor: "orange.light",
    p: "4",
    borderRadius: "xl",
    boxShadow: "0px 1px 6px 0px rgba(47, 54, 48, 0.15)", // i fucking suck this is darkForest or some shit im so sorry
  },
};

export default Sticky;
