const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs>
      <circle id="path-1" cx="9.625" cy="9.625" r="9.625"></circle>
    </defs>
    <g
      id="Help-icon"
      transform="translate(1.000000, 1.000000)"
      fill="currentColor"
    >
      <path
        d="M11,7.87703236e-13 C17.0751322,7.87703236e-13 22,4.92486775 22,11 C22,17.0751322 17.0751322,22 11,22 C4.92486775,22 -4.15223411e-14,17.0751322 -4.15223411e-14,11 C-4.15223411e-14,4.92486775 4.92486775,7.87703236e-13 11,7.87703236e-13 Z M5.67349198,12.3758465 L1.47252325,12.375357 C2.07564643,16.5907298 5.41013572,19.9249318 9.62564866,20.5276206 L9.62515221,16.3267651 C7.69265203,15.8294333 6.17117657,14.308205 5.67349198,12.3758465 Z M20.5274767,12.375357 L16.326508,12.3758465 C15.8289092,14.3078721 14.3078721,15.8289092 12.3758465,16.326508 L12.375357,20.5274767 C16.5903946,19.9244015 19.9244015,16.5903946 20.5274767,12.375357 Z M11.0343166,6.87513977 L10.966,6.875 L10.7941209,6.88004829 C8.61159249,6.98732491 6.875,8.790861 6.875,11 C6.875,13.2781746 8.72182541,15.125 11,15.125 C13.2781746,15.125 15.125,13.2781746 15.125,11 C15.125,8.73327521 13.2966917,6.8935747 11.0343166,6.87513977 Z M1.47237942,9.62564866 L5.67323487,9.62515221 C6.17065239,7.69231913 7.69231913,6.17065239 9.62515221,5.67323487 L9.62564866,1.47237942 C5.40980049,2.0751161 2.0751161,5.40980049 1.47237942,9.62564866 Z M12.375357,1.47252325 L12.3758465,5.67349198 C14.308205,6.17117657 15.8294333,7.69265203 16.3267651,9.62515221 L20.5276206,9.62564866 C19.9249318,5.41013572 16.5907298,2.07564643 12.375357,1.47252325 Z"
        id="Combined-Shape"
        transform="translate(11.000000, 11.000000) rotate(-30.000000) translate(-11.000000, -11.000000) "
      ></path>
    </g>
  </svg>
);

export default Icon;
