import { useToast } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import RunStatusPopoverBody from "src/components/RunStatusToast/popoverBody";
import { ConfigContext } from "src/contexts/configContextProvider";

const useRunStatusToast = () => {
  const { runStatus } = useContext(ConfigContext);
  const toast = useToast();
  const runStatusToastId = "run-status-toast";
  const [hidden, setHidden] = useState(false);

  if (!hidden && runStatus?.isRunning && !toast.isActive(runStatusToastId)) {
    toast({
      duration: null,
      id: runStatusToastId,
      isClosable: true,
      variant: "top-accent",
      position: "top",
      title: "Dig in progress",
      description: <RunStatusPopoverBody />,
      onCloseComplete: () => setHidden(true), // todo should they be able to get this to come back w/o refreshing page?
    });
  } else if (!runStatus?.isRunning && toast.isActive(runStatusToastId)) {
    // close
    toast.update(runStatusToastId, { duration: 0 });
  }
};

export default useRunStatusToast;
