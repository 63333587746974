const Link = {
  variants: {
    blue: {
      color: "blue.medium",
      _hover: { color: "blue.dark" },
    },
  },
};

export default Link;
