const Icon = () => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.85389 7.9991L9.85479 8L12.6832 5.17157C12.9064 4.94842 12.9064 4.58661 12.6832 4.36345C12.4601 4.14029 12.0983 4.14029 11.8751 4.36345L10.4506 5.78792L10.4506 0.571415C10.4506 0.255824 10.1948 -1.35836e-05 9.8792 -1.36112e-05C9.56361 -1.36388e-05 9.30777 0.255823 9.30777 0.571414V5.83854L7.83268 4.36345C7.60952 4.14029 7.24771 4.14029 7.02456 4.36345C6.8014 4.58661 6.8014 4.94842 7.02456 5.17157L9.85298 8L9.85389 7.9991ZM2.9967 0.000903368L2.99579 0L0.167367 2.82843C-0.0557892 3.05158 -0.0557891 3.41339 0.167368 3.63655C0.390524 3.85971 0.752333 3.85971 0.97549 3.63655L2.39998 2.21205V7.42859C2.39998 7.74418 2.65582 8.00001 2.97141 8.00001C3.287 8.00001 3.54284 7.74418 3.54284 7.42859V2.16148L5.01791 3.63655C5.24106 3.85971 5.60287 3.85971 5.82603 3.63655C6.04919 3.41339 6.04919 3.05158 5.82603 2.82843L2.9976 0L2.9967 0.000903368Z"
      fill="#364766"
    />
  </svg>
);

export default Icon;
