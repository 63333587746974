import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import LogoHeaderItem from "src/components/LogoHeaderItem";
import FrejasHand from "src/illustrations/FrejasHand";

interface Props {
  contentGridItem: React.FC;
}

const UnauthedLayout = ({ contentGridItem: ContentGridItem }: Props) => {
  return (
    <Flex flex={1} height="100%" alignItems="center" justifyContent="center">
      <Box position="absolute" top="20px" left="20px">
        <LogoHeaderItem />
      </Box>
      <Box position="absolute" top="220px" left="20px">
        <FrejasHand />
      </Box>
      <ContentGridItem />
    </Flex>
  );
};

export default UnauthedLayout;
