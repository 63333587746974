const Icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="-4 -4 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.5" fill="#2F3630" stroke="#2F3630" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1952 4.86189C12.4555 4.60154 12.8776 4.60154 13.138 4.86189C13.3983 5.12224 13.3983 5.54435 13.138 5.8047L7.13799 11.8047C6.87764 12.065 6.45553 12.065 6.19518 11.8047L3.52851 9.13803C3.26816 8.87768 3.26816 8.45557 3.52851 8.19522C3.78886 7.93487 4.21097 7.93487 4.47132 8.19522L6.66659 10.3905L12.1952 4.86189Z"
      fill="white"
    />
  </svg>
);

export default Icon;
