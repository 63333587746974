import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { isLoggedIn, setToken } from "./utils/auth";
import AuthedRouter from "./routers/AuthedRouter";
import UnauthedRouter from "./routers/UnauthedRouter";
import { authedPost } from "./api";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isLoggedIn());
  const [loading, setLoading] = useState<boolean>(true);

  const refreshToken = useCallback(async () => {
    const refreshResponse = await authedPost("/public/refresh");
    if (refreshResponse.ok) {
      const refreshBody = await refreshResponse.json();
      setToken(refreshBody.jwt);
    }
    setIsAuthenticated(isLoggedIn());
    if (!isLoggedIn()) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  return (
    <Box className="App" width="100vw" height="100vh">
      {!loading && !isAuthenticated && <UnauthedRouter />}

      {isAuthenticated && <AuthedRouter />}
    </Box>
  );
};

export default App;
