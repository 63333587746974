const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Icon-/-Settings-Solid"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M14.2222222,2 L14.8148148,4.90865385 L16.8641975,6.08653846 L19.7777778,5.125 L22,8.875 L19.7530864,10.7980769 L19.7530864,13.2019231 L22,15.125 L19.7777778,18.875 L16.8641975,17.9134615 L14.8148148,19.0913462 L14.2222222,22 L9.77777778,22 L9.18518519,19.0913462 L7.13580247,17.9134615 L4.22222222,18.875 L2,15.125 L4.24691358,13.2019231 L4.24691358,10.7980769 L2,8.875 L4.22222222,5.125 L7.13580247,6.08653846 L9.18518519,4.90865385 L9.77777778,2 L14.2222222,2 Z M12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 Z"
        id="🎨-Icon-style"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default Icon;
