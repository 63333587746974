const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Icon-/-Screenshot"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M19,2 C20.6568542,2 22,3.34314575 22,5 L22,19 C22,20.6568542 20.6568542,22 19,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,5 C2,3.34314575 3.34314575,2 5,2 L19,2 Z M19,7 L5,7 C4.44771525,7 4,7.44771525 4,8 L4,19 C4,19.5522847 4.44771525,20 5,20 L19,20 C19.5522847,20 20,19.5522847 20,19 L20,8 C20,7.44771525 19.5522847,7 19,7 Z M12,15 L12,17 L6,17 L6,15 L12,15 Z M18,12 L18,14 L6,14 L6,12 L18,12 Z M18,9 L18,11 L6,11 L6,9 L18,9 Z M5,3.5 C4.44771525,3.5 4,3.94771525 4,4.5 C4,5.05228475 4.44771525,5.5 5,5.5 C5.55228475,5.5 6,5.05228475 6,4.5 C6,3.94771525 5.55228475,3.5 5,3.5 Z M8,3.5 C7.44771525,3.5 7,3.94771525 7,4.5 C7,5.05228475 7.44771525,5.5 8,5.5 C8.55228475,5.5 9,5.05228475 9,4.5 C9,3.94771525 8.55228475,3.5 8,3.5 Z M11,3.5 C10.4477153,3.5 10,3.94771525 10,4.5 C10,5.05228475 10.4477153,5.5 11,5.5 C11.5522847,5.5 12,5.05228475 12,4.5 C12,3.94771525 11.5522847,3.5 11,3.5 Z"
        id="Combined-Shape"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default Icon;
