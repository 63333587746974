import React, { useCallback, useEffect, useState } from "react";
import { authedGet, authedPost } from "src/api";
import { getOrgId } from "src/utils/auth";

export interface OrgPreferences {
  id: number;
  orgId: number;
  preferences: SlackPreferences; // these types are a fucking mess
  createdAt: string;
}

export interface SlackPreferences {
  slackUsersToNotify: string[];
  slackChannelsToNotify: string[];
}

export interface OrgPreferenceIF {
  preferences: OrgPreferences;
  setOrgPreferences: (newPreferences: SlackPreferences) => Promise<void>;
}

const defaultPreference = {
  id: 0,
  orgId: getOrgId(),
  preferences: {
    slackUsersToNotify: [],
    slackChannelsToNotify: [],
  },
  createdAt: "",
};

export const OrgPreferenceContext = React.createContext<OrgPreferenceIF>({
  preferences: defaultPreference,
  setOrgPreferences: () => {
    return new Promise((_, reject) => {
      reject("setOrgPreferences not ready");
    });
  },
});

const OrgPreferenceContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [preferences, setPreferences] = useState<OrgPreferences>(
    defaultPreference
  );

  const fetchPreferences = useCallback(async () => {
    const preferenceResponse = await authedGet("/orgPreferences/slack");
    if (preferenceResponse.ok) {
      setPreferences(await preferenceResponse.json());
    }
  }, []);

  const updatePreferences = useCallback(
    async (newPreferences: SlackPreferences) => {
      const preferenceResponse = await authedPost(
        "/orgPreferences/slack",
        newPreferences
      ).then((resp) => resp.json());
      setPreferences(preferenceResponse);
    },
    []
  );

  useEffect(() => {
    fetchPreferences();
  }, [fetchPreferences]);

  return (
    <OrgPreferenceContext.Provider
      value={{
        preferences,
        setOrgPreferences: updatePreferences,
      }}
    >
      {children}
    </OrgPreferenceContext.Provider>
  );
};

export default OrgPreferenceContextProvider;
