import useRunStatusToast from "src/hooks/useRunStatusToast";

// this is dumb... useRunStatusToast causes the entire component using the hook to rerender when the toast rerenders
// i havent had my coffee yet so im sure there's a smarter way to manage this
// please fix this future pete
const Container = () => {
  useRunStatusToast();

  return <></>;
};

export default Container;
