import React, { useCallback, useEffect, useState } from "react";
import { authedGet, authedPatch } from "src/api";
import { logout } from "src/utils/auth";

export interface User {
  id: number;
  orgId: number;
  username: string;
  name: string;
  createdAt: string;
  status: "invited" | "active";
}

export interface Org {
  id: number;
  name: string;
  createdAt: string;
}

export interface UserContextIF {
  user?: User;
  org?: Org;
}

export interface UserActions {
  updateMe: (newMe: Partial<User>) => void;
}

export const UserContext = React.createContext<UserContextIF & UserActions>({
  updateMe: () => console.error("updateMe called before ready"),
});

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [userContext, setUserContext] = useState<UserContextIF>();

  useEffect(() => {
    const fetchMe = async () => {
      const meResp = await authedGet("/me");
      if (meResp.ok) {
        const me = await meResp.json();
        setUserContext(me);
      } else if (meResp.status < 500) {
        // 401/403/404 all indicate that theyre effectively unauthed
        logout();
      } else {
        // should probably panic
      }
    };
    fetchMe();
  }, []);

  const updateMe = useCallback(
    async (newMe: Partial<User>) => {
      const userUpdateResponse = await authedPatch("/me", newMe).then((resp) =>
        resp.json()
      );
      const newContext = { ...userContext, user: userUpdateResponse };
      setUserContext(newContext);
    },
    [userContext]
  );

  return (
    <UserContext.Provider
      value={{
        user: userContext?.user,
        org: userContext?.org,
        updateMe,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
