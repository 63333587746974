const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Icon-/-Articles"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M21,0 C22.6568542,0 24,1.34314575 24,3 L24,17 C24,18.6568542 22.6568542,20 21,20 L20,20 L20,21 C20,22.6568542 18.6568542,24 17,24 L3,24 C1.34314575,24 -1.42108547e-14,22.6568542 -1.42108547e-14,21 L-1.42108547e-14,7 C-1.42108547e-14,5.34314575 1.34314575,4 3,4 L3.999,4 L4,3 C4,1.34314575 5.34314575,0 7,0 L21,0 Z M17,6 L3,6 C2.44771525,6 2,6.44771525 2,7 L2,21 C2,21.5522847 2.44771525,22 3,22 L17,22 C17.5522847,22 18,21.5522847 18,21 L18,7 C18,6.44771525 17.5522847,6 17,6 Z M9,18 L9,20 L4,20 L4,18 L9,18 Z M16,12 L16,20 L10,20 L10,12 L16,12 Z M21,2 L7,2 C6.44771525,2 6,2.44771525 6,3 L6,4 L17,4 C18.6568542,4 20,5.34314575 20,7 L20,18 L21,18 C21.5522847,18 22,17.5522847 22,17 L22,3 C22,2.44771525 21.5522847,2 21,2 Z M9,15 L9,17 L4,17 L4,15 L9,15 Z M9,12 L9,14 L4,14 L4,12 L9,12 Z M16,9 L16,11 L4,11 L4,9 L16,9 Z"
        id="Combined-Shape"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default Icon;
