const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Icon-/-Cart"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M8.5,18 C9.32842712,18 10,18.6715729 10,19.5 C10,20.3284271 9.32842712,21 8.5,21 C7.67157288,21 7,20.3284271 7,19.5 C7,18.6715729 7.67157288,18 8.5,18 Z M16.5,18 C17.3284271,18 18,18.6715729 18,19.5 C18,20.3284271 17.3284271,21 16.5,21 C15.6715729,21 15,20.3284271 15,19.5 C15,18.6715729 15.6715729,18 16.5,18 Z M4.45454545,3 C4.9314237,3 5.34195068,3.33673171 5.43522888,3.80439829 L5.43522888,3.80439829 L6.13906938,7.33322592 L20,7.33322592 C20.6271365,7.33322592 21.0995032,7.90381594 20.982417,8.51992557 L20.982417,8.51992557 L20.2188409,12.5378773 C19.7704903,14.8971068 17.7082099,16.604379 15.3067557,16.604379 L15.3067557,16.604379 L10.0500612,16.604379 C7.66566999,16.604379 5.61303509,14.9207205 5.1466441,12.5823876 L5.1466441,12.5823876 L3.63430288,5 L2,5 C1.44771525,5 1,4.55228475 1,4 C1,3.44771525 1.44771525,3 2,3 L2,3 Z M18.7920612,9.33322592 L6.53797835,9.33322592 L7.10801094,12.1911841 C7.38784553,13.5941839 8.61942647,14.604379 10.0500612,14.604379 L10.0500612,14.604379 L15.3067557,14.604379 C16.7476283,14.604379 17.9849965,13.5800157 18.2540069,12.164478 L18.2540069,12.164478 L18.7920612,9.33322592 Z"
        id="Combined-Shape"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default Icon;
