import { useCallback, useContext, useEffect } from "react";
import { UserContext } from "src/contexts/userContextProvider";
import { getOrgId, getToken, getUserId } from "src/utils/auth";

declare global {
  interface Window {
    HubSpotConversations: any;
    _hsq: any;
    hsConversationsOnReady: any;
  }
}

const useHubspotChat = () => {
  const { user } = useContext(UserContext);

  const identify = useCallback(async () => {
    if (getToken() && user && !!window.HubSpotConversations) {
      // only show widget for logged in users
      const _hsq = window._hsq || [];

      // identify
      _hsq.push([
        "identify",
        {
          email: user?.username,
          name: user?.name,
          id: getUserId(),
          orgId: getOrgId(),
        },
      ]);

      // track page view to force identification to send
      _hsq.push(["trackPageView"]);

      // reload the widget
      setTimeout(window.HubSpotConversations.widget.load, 1000);
    }
  }, [user]);
  useEffect(() => {
    if (window.HubSpotConversations) {
      identify();
    } else {
      window.hsConversationsOnReady = [identify];
    }
  }, [identify, user]);
};

export default useHubspotChat;
