import { useEffect } from "react";
import { Elastic, Expo, Linear, TimelineMax, TweenMax, gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import "./flowerStyle.css";

// shamelessly stolen from https://codepen.io/MarvinRudolph/pen/VNMXWO

const Flower = () => {
  useEffect(() => {
    gsap.registerPlugin(MotionPathPlugin);
    (() => {
      const LEAF_SVG =
        '<svg width="10" height="62" viewBox="0 0 10 62" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" stroke="black" clip-rule="evenodd" d="M4.99976 61C7.2089 61 7.99976 47.5685 8.99976 31C9.99976 14.4315 7.2089 1 4.99976 1C2.79062 1 -0.000237778 14.4315 0.999762 31C1.99976 47.5685 2.79062 61 4.99976 61Z" stroke="white"/></svg>';

      const Selector = {
        CENTER: ".flower__center",
        LEAF: ".flower__leaf",
        LEAF_INNER: ".flower__leaf-inner",
        LEAVES: ".flower__leaves",
      };

      const ClassName = {
        CENTER: "flower__center",
        LEAF: "flower__leaf",
      };

      class FlowerLoader {
        constructor(element) {
          this._element = element;
          this._flowerLeaves = element.querySelector(Selector.LEAVES);
          this._numberOfLeaves = 24;
          this._rotation = 360 / this._numberOfLeaves;
          // these magic numbers are somehow related to centering the petals around the center
          this._path = [
            { x: 12, y: -46 },
            { x: 13, y: -47 },
            { x: 12, y: -46 },
            { x: 11, y: -45 },
            { x: 12, y: -46 },
          ];
          this._location = { x: this._path[0].x, y: this._path[0].y };
          this._tn1 = TweenMax.to(this._location, this._numberOfLeaves, {
            motionPath: {
              curviness: 1.5,
              path: this._path,
            },
            ease: Linear.easeNone,
          });

          this._initialize();
        }

        _initialize() {
          this._addLeaves();
        }

        _addLeaves() {
          for (let i = 0; i < this._numberOfLeaves; i++) {
            const leafElement = document.createElement("div");
            leafElement.className = ClassName.LEAF;
            leafElement.innerHTML = `<div class="flower__leaf-inner">${LEAF_SVG}</div>`;
            this._tn1.time(i);

            TweenMax.set(leafElement, {
              x: this._location.x,
              y: this._location.y,
              rotation: this._rotation * i,
            });

            this._flowerLeaves.appendChild(leafElement);
          }

          this._animate();
        }

        _animate() {
          const leaves = this._flowerLeaves.querySelectorAll(
            Selector.LEAF_INNER
          );

          const timeline = new TimelineMax({
            onComplete: () => {
              timeline.restart(true);
            },
          });

          timeline
            .add(
              // petals pop in one by one
              TweenMax.staggerTo(
                leaves,
                0.1,
                {
                  scale: 1,
                  ease: Elastic.easeOut.config(1.1, 0.75),
                },
                0.05
              )
            )
            .add(
              // petals grow before spin animation
              TweenMax.to(leaves, 0.3, {
                scale: 1.25,
                ease: Elastic.easeOut.config(1, 1),
              })
            )
            .add(
              // petals spin
              TweenMax.to(this._element.querySelector(Selector.LEAVES), 1, {
                duration: 1,
                rotation: 360,
                ease: Expo.easeInOut,
              })
            )
            .add(
              // petals pop out all at once
              TweenMax.to(leaves, 0.75, {
                scale: 0,
                ease: Elastic.easeInOut.config(1.1, 0.75),
              })
            );
        }
      }

      return new FlowerLoader(document.body.querySelector(".flower"));
    })();
  }, []);

  return (
    <div className="container">
      <div className="flower">
        <div className="flower__center"></div>
        <div className="flower__leaves"></div>
      </div>
    </div>
  );
};

export default Flower;
