import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  LinkBox,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { authedPost } from "src/api";
import LogoHeaderItem from "src/components/LogoHeaderItem";
import { UserContext } from "src/contexts/userContextProvider";
import useEventTracker from "src/hooks/useEventTracker";
import {
  ArticlesIcon,
  AvatarIcon,
  CartIcon,
  ChartIcon,
  CheckCircleIcon,
  CogIcon,
  DigIcon,
  GlossaryIcon,
  PencilIcon,
  ScreenshotIcon,
  SeedsIcon,
  UserIcon,
} from "src/icons";
import { logout } from "src/utils/auth";

export const NavItem = ({
  to,
  label,
  icon: Icon,
  useHash,
  defaultSelected,
}: {
  to: string;
  label: string;
  icon?: React.FC;
  useHash?: boolean;
  defaultSelected?: boolean;
}) => {
  const location = useLocation();
  const currentLocation = useHash ? location.hash : location.pathname;
  const isSelected = currentLocation.startsWith(to) || defaultSelected;
  return (
    <LinkBox
      as="nav"
      marginTop="0"
      _hover={
        isSelected
          ? {}
          : {
              color: "dark.dark",
            }
      }
      _active={{
        color: "green.dark",
      }}
      color={isSelected ? "dark.dark" : "dark.medium"}
      fontWeight={isSelected ? "semibold" : "medium"}
      width={useHash ? "90%" : "100%"}
    >
      <Link to={to}>
        <HStack
          alignItems="center"
          spacing="2"
          paddingLeft="2"
          paddingRight="2"
          paddingTop="1"
          paddingBottom="1"
          backgroundColor={isSelected ? "pastel.lightgreen" : ""}
          roundedLeft="lg"
          roundedRight={useHash ? "lg" : ""}
          w="100%"
        >
          {Icon && <Icon />}
          <Text fontSize="sm">{label}</Text>
        </HStack>
      </Link>
    </LinkBox>
  );
};

const Sidebar = () => {
  const track = useEventTracker();
  const { user } = useContext(UserContext);
  const logoutCallback = useCallback(async () => {
    await authedPost("/public/expire");
    logout();
  }, []);
  return (
    <VStack width="213px" height="100vh" bgColor="gray.medium">
      <Box
        height="20"
        borderBottomWidth="1px"
        borderBottomColor="gray.medium"
        borderBottomStyle="solid"
        width="100%"
        padding="20px 0 0 28px"
      >
        <Link to="/">
          <LogoHeaderItem />
        </Link>
      </Box>
      <Box w="100%" paddingLeft="8" paddingTop="2">
        <NavItem to="/dashboard" label="Dashboard" icon={ChartIcon} />
        <NavItem to="/roundup" label="Roundup" icon={CheckCircleIcon} />
        <NavItem
          to="/article-requests"
          label="Article requests"
          icon={PencilIcon}
        />
        <Text
          textStyle="Overline"
          marginBottom="4"
          marginTop="4"
          paddingLeft="2"
        >
          SET UP
        </Text>
        <VStack alignItems="flex-start" marginBottom="10" spacing="1">
          <NavItem to="/setup" label="Set up" icon={SeedsIcon} />
          <NavItem to="/glossary" label="Glossary" icon={GlossaryIcon} />
        </VStack>
        <Text textStyle="Overline" marginBottom="4" paddingLeft="2">
          RESULTS
        </Text>
        <VStack alignItems="flex-start" marginBottom="10" spacing="1">
          <NavItem to="/digs" label="Digs" icon={DigIcon} />
          <NavItem to="/articles" label="Knowledge base" icon={ArticlesIcon} />
          <NavItem to="/pages" label="Application" icon={ScreenshotIcon} />
        </VStack>
        <Text textStyle="Overline" marginBottom="4" paddingLeft="2">
          SETTINGS
        </Text>
        <VStack alignItems="flex-start" spacing="1">
          <NavItem to="/team" label="Team" icon={UserIcon} />
          <NavItem to="/integrations" label="Integrations" icon={CogIcon} />
          <NavItem to="/billing" label="Billing" icon={CartIcon} />
        </VStack>
      </Box>
      <Spacer />
      <Flex
        height="20"
        borderTopWidth="1px"
        borderTopColor="gray.medium"
        borderTopStyle="solid"
        width="100%"
        alignItems="center"
        paddingLeft="8"
        paddingRight="8"
      >
        <Popover placement="top-start">
          {({ onClose, isOpen }) => (
            <>
              <PopoverTrigger>
                <Button
                  variant="ghost"
                  width="100%"
                  size="lg"
                  paddingLeft="0"
                  onClick={() =>
                    track("sidebar_popover_trigger_click", { isOpen })
                  }
                >
                  <Flex alignItems="center" marginRight="auto">
                    <AvatarIcon />
                    {user && (
                      <Text
                        marginLeft="2"
                        color="dark.medium"
                        fontSize="14px"
                        fontWeight="500"
                        maxWidth="140px"
                        _hover={{
                          color: "green.dark",
                        }}
                        _active={{
                          color: "green.dark",
                        }}
                        isTruncated
                      >
                        {`Hi, ${user.name || "there"}!`}
                      </Text>
                    )}
                  </Flex>
                </Button>
              </PopoverTrigger>
              <PopoverContent p="5" boxShadow="xl">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>
                  <Text textStyle="Overline">LOGGED IN AS</Text>
                  <Heading
                    size="sm"
                    fontWeight="300"
                    color="dark.dark"
                    mt="2"
                    mb="1"
                  >
                    {user?.name}
                  </Heading>
                  <Text size="sm" fontWeight="300" mb="4">
                    {user?.username}
                  </Text>
                </PopoverHeader>
                <PopoverBody>
                  <List>
                    <ListItem>
                      <Button
                        _hover={{
                          color: "green.dark",
                        }}
                        _active={{
                          color: "green.dark",
                        }}
                        fontWeight="500"
                        fontSize="sm"
                        variant="unstyled"
                        as={Link}
                        to="/preferences"
                        onClick={onClose}
                      >
                        Preferences
                      </Button>
                    </ListItem>
                    <ListItem fontWeight="500">
                      <Button
                        _hover={{
                          color: "green.dark",
                        }}
                        _active={{
                          color: "green.dark",
                        }}
                        fontWeight="500"
                        fontSize="sm"
                        variant="unstyled"
                        onClick={logoutCallback}
                      >
                        Log out
                      </Button>
                    </ListItem>
                  </List>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>
    </VStack>
  );
};

export default Sidebar;
