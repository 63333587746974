const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Icon-/-User"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M7.99980252,15.5026549 L8,18 L7,18 C6.48716416,18 6.06449284,18.3860402 6.00672773,18.8833789 L6,19 L6,22 L3,22 C3,18.5528646 4.85505457,16.4097338 7.99980252,15.5026549 Z M16.0011952,15.5029428 C19.0655203,16.3871558 20.9050038,18.4452187 20.9964235,21.7400195 L21,22 L18,22 L18,19 C18,18.4871642 17.6139598,18.0644928 17.1166211,18.0067277 L17,18 L16,18 L16.0011952,15.5029428 Z M12,15 C13.082117,15 14.0860955,15.0875972 15.0009348,15.2641139 L15,18 L9,18 L9.0000614,15.2639217 C9.91463339,15.0875337 10.9182758,15 12,15 Z M12,3 C13.978867,3 15.6221107,4.43697434 15.9431805,6.32437252 C18.3657351,6.75398366 20,7.56735073 20,8.5 C20,9.43277787 18.3652843,10.2462406 15.942289,10.6758771 C15.6189707,12.5569471 13.9698246,14 12,14 C10.0301754,14 8.38102929,12.5569471 8.05750735,10.6757872 C5.63471566,10.2462406 4,9.43277787 4,8.5 C4,7.56735073 5.63426493,6.75398366 8.05670881,6.32430065 C8.37788931,4.43697434 10.021133,3 12,3 Z"
        id="Combined-Shape"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default Icon;
