import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

const AUTH_COOKIE = "daisy_auth_token";

export const isLoggedIn = () => !!getToken();

export const getToken = (): string | undefined => {
  return Cookies.get(AUTH_COOKIE);
};

export const getUserId = (): number => {
  const token = getToken();
  return token ? +jwt.decode(token, { json: true })?.sub : 0;
};

export const getOrgId = (): number => {
  const token = getToken();
  return token ? +jwt.decode(token, { json: true })?.iss : 0;
};

export const setToken = (token: string) => {
  Cookies.set(AUTH_COOKIE, token, { secure: true });
};

export const logout = () => {
  Cookies.remove(AUTH_COOKIE);
  window.location.reload();
};
