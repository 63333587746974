import { CheckCircleIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { post } from "src/api";
import Link from "src/components/design-system/Link";
import PageHeader from "src/components/design-system/PageHeader";

const ResetPasswordForm = () => {
  const location = useLocation();
  const resetToken = new URLSearchParams(location.search).get("resetToken");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const submitReset = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      setSuccess("");
      const res = await post("/public/reset", {
        password,
        resetToken,
      });
      if (res.status > 204) {
        throw new Error(res.statusText);
      }
      setSuccess("Your password has been reset! Please log in again");
    } catch (e) {
      console.error(e);
      setError(
        "Something went wrong -- please request a new reset email and try again"
      );
    } finally {
      setLoading(false);
    }
  }, [password, resetToken]);

  const submitOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        submitReset();
      }
    },
    [submitReset]
  );

  return (
    <GridItem rowSpan={9}>
      <Grid templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={1} />
        <GridItem colSpan={2}>
          <PageHeader fontSize="44px" textAlign="center">
            Reset your password
          </PageHeader>
          <Text>
            When you’re all set, <Link to="/login">sign in</Link>
          </Text>
          <VStack marginTop="8" spacing="32px" onKeyPress={submitOnEnter}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder="Your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
              />
              <InputRightElement>
                <IconButton
                  aria-label={showPassword ? "hide password" : "show password"}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  size="sm"
                  variant="ghost"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              w="100%"
              variant="secondary"
              disabled={!password}
              onClick={submitReset}
              isLoading={loading}
              loadingText="Submitting"
              type="submit"
            >
              Reset password
            </Button>
            {error && (
              <Alert status="error">
                <HStack spacing="10px">
                  <AlertIcon />
                  <Text>{error}</Text>
                </HStack>
              </Alert>
            )}
            {success && (
              <Alert status="success">
                <HStack spacing="10px">
                  <CheckCircleIcon />
                  <Text>{success}</Text>
                </HStack>
              </Alert>
            )}
          </VStack>
        </GridItem>
        <GridItem colSpan={1} />
      </Grid>
    </GridItem>
  );
};

export default ResetPasswordForm;
