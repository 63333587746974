import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useState } from "react";

interface Props {
  password: string;
  setPassword: (newPassword: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const PasswordInput = ({ password, setPassword, inputRef }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={showPassword ? "text" : "password"}
        placeholder="Your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        id="password"
        ref={inputRef}
      />
      <InputRightElement>
        <IconButton
          aria-label={showPassword ? "hide password" : "show password"}
          icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
          size="sm"
          variant="ghost"
          onClick={() => setShowPassword(!showPassword)}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
