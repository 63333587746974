const Textarea = {
  variants: {
    outline: {
      borderColor: "gray.medium",
      _placeholder: { color: "dark.light" },
    },
  },
};

export default Textarea;
