import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import usePageTracker from "src/hooks/usePageTracker";
import UnauthedPage from "src/pages/UnauthedPage";

const RouterGuts = () => {
  usePageTracker();

  return (
    <Switch>
      <Route path="/signup">
        <UnauthedPage component="signup" />
      </Route>
      <Route path="/forgot">
        <UnauthedPage component="forgot" />
      </Route>
      <Route path="/reset">
        <UnauthedPage component="reset" />
      </Route>
      <Route>
        <UnauthedPage component="login" />
      </Route>
    </Switch>
  );
};

const UnauthedRouter = () => {
  return (
    <Router>
      <RouterGuts />
    </Router>
  );
};

export default UnauthedRouter;
