import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";
import theme from "./theme";
import { BaseOpenTelemetryComponent } from "@opentelemetry/plugin-react-load";
import Tracer from "./utils/tracer";

Tracer("meadow");

BaseOpenTelemetryComponent.setTracer("meadow");

Sentry.init({
  dsn:
    "https://66bd50514b3d43ee8e95000120aaf918@o578301.ingest.sentry.io/5734454",
  tracesSampleRate: 0.01, // we are but poor free sentry users who do not wish to blow our budget on performance analysis (for now)
});

ReactGA.initialize("UA-193570952-1");

function sendToAnalytics({
  id,
  name,
  value,
}: {
  id: string;
  name: string;
  value: number;
}) {
  ReactGA.timing({
    category: "Web Vitals",
    variable: name,
    value: Math.round(name === "CLS" ? value * 1000 : value), // in milliseconds
    label: id,
  });
}

reportWebVitals(sendToAnalytics);

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </ChakraProvider>,
  document.getElementById("root")
);
