import { Flex } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import React from "react";
import logoIcon from "./logo_icon.svg";
import logoText from "./logo_text.svg";
import "./style.css";

const LogoHeaderItem = () => {
  return (
    <Flex alignItems="left" className="logo-header-item-container">
      <Image src={logoIcon} h="45px" className="logo-header-item-daisy-icon" />
      <Image src={logoText} h="45px" />
    </Flex>
  );
};

export default LogoHeaderItem;
