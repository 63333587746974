import { Center, Box, Text, Button, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useEventTracker from "src/hooks/useEventTracker";
import Loader from "src/components/design-system/Loader";
import PageHeader from "src/components/design-system/PageHeader";

interface Props {
  error: Error;
}

const ErrorFallback = ({ error }: Props) => {
  const [loading, setLoading] = useState(true);
  const track = useEventTracker();

  useEffect(() => {
    track("error fallback loaded", {
      name: error.name,
      message: error.message,
    });
    if (error.name === "ChunkLoadError") {
      // fixme this is a hack
      // when we deploy to surge it nukes the old asset chunks immediately
      // thus lazy loading fails cuz the manifest refers to a chunk url that no longer exists
      //
      // this forces the app the refresh (and thus load the updated manifest) when this happens
      // it's annoying but way better than all active sessions breaking every time we deploy
      console.warn("reloading due to ChunkLoadError 🤞", error);
      window.location.reload();
    } else {
      setLoading(false);
    }
  }, [error, track]);

  return loading ? (
    <Loader />
  ) : (
    <Center w="100%" h="100%" className="not-found">
      <Box margin="auto">
        <PageHeader>That shouldn’t happen</PageHeader>
        <Text>Something went wrong on this page</Text>
        <Text>
          Please give it a{" "}
          <Button
            variant="ghost"
            onClick={() => {
              track("error refresh button clicked");
              window.location.reload();
            }}
          >
            refresh
          </Button>{" "}
          and try again
        </Text>
        <Text>
          Please let us know at{" "}
          <Link
            onClick={() => track("error email link clicked")}
            href={`mailto:support@alldaisy.com?body=${encodeURIComponent(
              `Hi!\nI’m reporting an error on page ${window.location.href}`
            )}`}
          >
            support@alldaisy.com
          </Link>{" "}
          so we can fix this!
        </Text>
      </Box>
    </Center>
  );
};

export default ErrorFallback;
