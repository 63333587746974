import React, { useEffect, useState } from "react";
import { authedGet, parseListResponse } from "src/api";

export interface Evaluation {
  id: number;
  orgId: number;
  createdAt: string;
  complete: boolean;
}

export interface EvaluationSummary {
  id: number;
  orgId: number;
  createdAt: string;
  pagesVisited: number;
  issuesFound: number;
  brokenLinks: number;
  bannedTerms: number;
  pagesWithIssues: number;
  helpdocsWithIssues: number;
}

export interface DigContextIF {
  latestDig: number;
  digs: Evaluation[];
  digSummaries: Record<number, EvaluationSummary>;
  digSummariesLoading: boolean;
  digsLoading: boolean;
}

export const DigContext = React.createContext<DigContextIF>({
  latestDig: 0,
  digs: [],
  digSummaries: {},
  digSummariesLoading: true,
  digsLoading: true,
});

const DigContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [digs, setDigs] = useState<Evaluation[]>([]);
  const [digsLoading, setDigsLoading] = useState<boolean>(true);
  const [digSummaries, setDigSummaries] = useState<
    Record<number, EvaluationSummary>
  >({});
  const [digSummariesLoading, setDigSummariesLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDigs = async () => {
      try {
        const digsSummariesById = await authedGet(
          "/evaluations/summaries"
        ).then((resp) => resp.json());
        setDigSummaries(digsSummariesById);
      } finally {
        setDigSummariesLoading(false);
      }
    };
    fetchDigs();
  }, [setDigSummariesLoading]);

  useEffect(() => {
    const fetchDigs = async () => {
      const evaluationList = await authedGet("/evaluations").then(
        parseListResponse
      );
      setDigs(evaluationList);
      setDigsLoading(false);
    };
    setDigsLoading(true);
    fetchDigs();
  }, []);

  return (
    <DigContext.Provider
      value={{
        digs,
        latestDig: Math.max(...Array.from(digs.map((dig) => dig.id))),
        digSummaries,
        digSummariesLoading,
        digsLoading,
      }}
    >
      {children}
    </DigContext.Provider>
  );
};

export default DigContextProvider;
