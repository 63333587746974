import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { getOrgId, getUserId } from "src/utils/auth";

declare global {
  interface Window {
    analytics: any;
  }
}

const usePageTracker = () => {
  const location = useLocation();

  const trackPage = (page: string) => {
    ReactGA.set({
      page,
      userId: getUserId(),
      dimension1: getUserId(),
      orgId: getOrgId(),
      dimension2: getOrgId(),
    });
    ReactGA.pageview(page);

    if (!!window.analytics) {
      window.analytics.page();
    }
  };

  useEffect(() => {
    console.debug(
      `tracked ${location.pathname + location.search + location.hash}`
    );
    trackPage(location.pathname + location.search + location.hash);
  }, [location]);
};

export default usePageTracker;
