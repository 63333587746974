import { clone } from "ramda";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { authedGet, authedPost, parseListResponse } from "src/api";
import { getOrgId } from "src/utils/auth";
import { ConfigContext } from "./configContextProvider";

export interface SitePage {
  id: number;
  loginConfig?: number;
  orgId: number;
  createdAt: string;
  lastSeen: string;
  url: string;
  name: string;
  isHelpdoc: boolean;
  upToDate: boolean;
}

interface SitePageContextIF {
  sitePages: SitePage[];
  helpdocPages: SitePage[];
  helpdocPagesById: Record<number, SitePage>;
  appPages: SitePage[];
  appPagesById: Record<number, SitePage>;
  sitePagesById: Record<number, SitePage>;
  sitePagesByIdWithIgnored: Record<number, SitePage>;
  setPageName: (id: number, newName: string) => void;
}

export const SitePageContext = React.createContext<SitePageContextIF>({
  sitePages: [],
  helpdocPages: [],
  helpdocPagesById: {},
  appPages: [],
  appPagesById: {},
  sitePagesById: {},
  sitePagesByIdWithIgnored: {},
  setPageName: () => console.error("setPageName called before ready"),
});

const SitePageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { ignoreRules } = useContext(ConfigContext); // fixme i hate context in context
  const [sitePages, setSitePages] = useState<SitePage[]>([]);
  const [sitePagesByIdWithIgnored, setSitePagesByIdWithIgnored] = useState<
    Record<number, SitePage>
  >({});

  const sitePagesById: Record<number, SitePage> = sitePages.reduce(
    (obj: Record<number, SitePage>, page: SitePage) => {
      obj[page.id] = page;
      return obj;
    },
    {}
  );

  const helpdocPages = sitePages.filter((page) => page.isHelpdoc);
  const helpdocPagesById = helpdocPages.reduce(
    (obj: Record<number, SitePage>, page: SitePage) => {
      obj[page.id] = page;
      return obj;
    },
    {}
  );

  const appPages = sitePages.filter((page) => !page.isHelpdoc);
  const appPagesById = appPages.reduce(
    (obj: Record<number, SitePage>, page: SitePage) => {
      obj[page.id] = page;
      return obj;
    },
    {}
  );

  const setPageName = useCallback(
    async (id: number, newName: string) => {
      const newSitePage = await authedPost(`/sitePages/${id}`, {
        orgId: getOrgId(),
        name: newName,
      }).then((resp) => resp.json());
      const updatedIndex = sitePages.findIndex(
        (sitePage) => sitePage.id === newSitePage.id
      );
      const newPages = clone(sitePages);
      newPages.splice(updatedIndex, 1, newSitePage);
      setSitePages(newPages);
    },
    [sitePages]
  );

  useEffect(() => {
    // fixme should probably at least wait until ignoreRules is loaded
    const fetchSitePages = async () => {
      const fetchedSitePages = await authedGet("/sitePages").then(
        parseListResponse
      );
      setSitePagesByIdWithIgnored(
        fetchedSitePages.reduce(
          (obj: Record<number, SitePage>, page: SitePage) => {
            obj[page.id] = page;
            return obj;
          },
          {}
        )
      );
      setSitePages(
        fetchedSitePages.filter(
          (page) =>
            !ignoreRules.find(
              (rule) =>
                rule.entity === "sitePage" && rule.ignoredValue === `${page.id}`
            )
        )
      );
    };
    fetchSitePages();
  }, [ignoreRules]);

  return (
    <SitePageContext.Provider
      value={{
        sitePages,
        helpdocPages,
        helpdocPagesById,
        appPages,
        appPagesById,
        sitePagesById,
        setPageName,
        sitePagesByIdWithIgnored,
      }}
    >
      {children}
    </SitePageContext.Provider>
  );
};

export default SitePageContextProvider;
