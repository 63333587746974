import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

const withPageName = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  pageName: string
) => {
  return (props: P) => {
    useEffect(() => {
      document.title = pageName;
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withPageName;
