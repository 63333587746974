import config from "src/config";
import { getToken } from "src/utils/auth";

export const get = async (path: string): Promise<Response> => {
  return fetch(`${config.API_HOST}${path}`, { credentials: "include" });
};

export const post = async (path: string, body?: any): Promise<Response> => {
  return fetch(`${config.API_HOST}${path}`, {
    body: JSON.stringify(body),
    method: "POST",
    headers: { "content-type": "application/json" },
    credentials: "include",
  });
};

const authedRequest = async (
  path: string,
  method: "GET" | "POST" | "PATCH" | "DELETE",
  body?: any
): Promise<Response> => {
  return fetch(`${config.API_HOST}${path}`, {
    method,
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(body),
    credentials: "include",
  });
};

export const authedGet = async (path: string): Promise<Response> => {
  return authedRequest(path, "GET");
};

export const authedPost = async (
  path: string,
  body?: any
): Promise<Response> => {
  return authedRequest(path, "POST", body);
};

export const authedPatch = async (
  path: string,
  body?: any
): Promise<Response> => {
  return authedRequest(path, "PATCH", body);
};

export const authedDelete = async (
  path: string,
  body?: any
): Promise<Response> => {
  return authedRequest(path, "DELETE", body);
};

// todo migrate everybody and rename (just tryna duck some messy merge conflicts)
export const parseListResponseTyped = async <T>(
  response: Response
): Promise<T[]> => {
  return parseListResponse(response);
};

export const parseListResponse = async (response: Response): Promise<any[]> => {
  // an unfortunate consequence of golang's refusal to serialize empty array as [], instead opting for null
  try {
    const json = await response.json();
    return json || ([] as any[]);
  } catch (e) {
    return [];
  }
};
