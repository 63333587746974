const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g
      id="Icon-/-Filter"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M4,2 C3.44771525,2 3,2.44771525 3,3 L10,12 L10,18.4014134 C10.8496094,19.3615877 11.8880208,20.5517171 13.1152344,21.9718018 C13.759977,21.8698923 13.8867798,21.6401367 14,21.0772095 C14,18.3370565 14,15.31132 14,12 L21,3 C21,2.44771525 20.5522847,2 20,2 L4,2 Z"
        id="🎨-Icon-style"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default Icon;
