export const concrete = "#98A39C";
export const swamp = "#57645B";
export const darkForest = "#2F3630";
export const white = "#FFFFFF";
export const vanilla = "#FAF9F7";
export const fog = "#DDE3DF";
export const butter = "#FFFDAB";
export const daisyYellow = "#FFF92F";
export const moldyLemon = "#D7CD4E";
export const pistachio = "#E1EBDA";
export const seaGlass = "#88A990";
export const grass = "#32523A";
export const glacier = "#EAF0F1";
export const scarab = "#007EA6";
export const slime = "#2C6671";
export const bubblegum = "#FFDCDA";
export const tulip = "#FF7595";
export const old = "#B72371";
export const creamsicle = "#F8EACD";
export const pasta = "#EABF66";
export const honey = "#A97302";

const colors = {
  dark: {
    light: concrete,
    medium: swamp,
    dark: darkForest,
  },
  black: {
    light: white,
    medium: vanilla,
    dark: fog,
  },
  gray: {
    light: white,
    medium: vanilla,
    dark: fog,
  },
  yellow: {
    light: butter,
    medium: daisyYellow,
    dark: moldyLemon,
  },
  green: {
    light: pistachio,
    medium: seaGlass,
    dark: grass,
  },
  blue: {
    light: glacier,
    medium: scarab,
    dark: slime,
  },
  red: {
    light: bubblegum,
    medium: tulip,
    dark: old,
  },
  orange: {
    light: creamsicle,
    medium: pasta,
    dark: honey,
  },
  // SHAME ON YOU FREJA
  sidebar: {
    background: "#FAF9F7",
    divider: "#DCDCDC",
  },
  pastel: {
    peach: "#F5BBA9",
    lightgreen: "#E1EBDA",
    darkgreen: "#32523A",
    brown: "#C5A59D",
    lavender: "#DBD6F3",
    salmon: "#F5926F",
    orange: "#F7A900",
    cobalt: "#827D9C",
    gray: "#C4C0CB",
    blue: "#20326D",
  },
};

export default colors;
