const Alert = {
  variants: {
    darkSuccess: {
      container: {
        bg: "green.dark",
        color: "white",
      },
    },
  },
};

export default Alert;
