const Icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="-4 -4 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H4V16H0V0ZM6 4.44453H10V16.0001H6V4.44453ZM16 9.77786H12V16.0001H16V9.77786Z"
      fill="currentColor"
    />
  </svg>
);

export default Icon;
