const Icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / Seeds">
      <path
        id="Path 13"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19135 18.0435C7.19135 18.0435 9.76797 18.0423 10.4234 18.7101C11.0789 19.3778 11.0893 22.0146 11.0893 22.0146C11.0893 22.0146 8.69628 22.1955 7.76183 21.2435C6.82738 20.2915 7.19135 18.0435 7.19135 18.0435Z"
        fill="currentColor"
      />
      <path
        id="Path 13_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19135 19.0435C2.19135 19.0435 4.76797 19.0447 5.42344 18.3769C6.07891 17.7091 6.08932 15.0724 6.08932 15.0724C6.08932 15.0724 3.69628 14.8915 2.76183 15.8435C1.82738 16.7955 2.19135 19.0435 2.19135 19.0435Z"
        fill="currentColor"
      />
      <path
        id="Path 14"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.34625 7.7708C5.27396 7.6509 5.31423 7.49502 5.43553 7.42512L6.32294 6.91376L12.5328 17.4819L11.7508 17.9239C11.633 17.9905 11.4836 17.9512 11.4137 17.8353L5.34625 7.7708Z"
        fill="currentColor"
      />
      <path
        id="Path 14_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.49739L16.5078 1.12013C16.6259 1.05332 16.7757 1.09296 16.8454 1.20944L22.8686 11.2828C22.9406 11.4033 22.8993 11.5596 22.7771 11.6287L13.2798 17L7 6.49739Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Icon;
