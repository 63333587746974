import { useCallback, useContext } from "react";
import { UserContext } from "src/contexts/userContextProvider";

declare global {
  interface Window {
    analytics: any;
  }
}

const useEventTracker = () => {
  const { user } = useContext(UserContext);

  const track = useCallback(
    (eventName: string, customValues?: Record<string, any>) => {
      if (!!window.analytics) {
        const defaultValues = {
          user,
        };
        window.analytics.track(eventName, {
          ...customValues,
          ...defaultValues,
        });
      }
    },
    [user]
  );

  return track;
};

export default useEventTracker;
